<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row class="dense-wrapper pt-2">
          <v-col cols="12" md="6">
            <v-text-field type="text"
                          filled
                          label="Назва"
                          v-model="name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text"
                          filled
                          label="Альфа ім’я"
                          v-model="alpha_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="alpha_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="4">
            <v-select label="Тип смс сервісу" v-model="service_type"
                      :items="sms_service_sender_type_select"
                      color="grey" filled hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="service_type ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="4">
            <v-select label="Тип повідомлення" v-model="message_type"
                      :items="sms_send_message_type_select"
                      color="grey" filled hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="message_type ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="4">
            <v-select label="Вид авторизації" v-model="auth_type"
                      :items="sms_authorization_type_select"
                      color="grey" filled hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="auth_type ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text"
                          filled
                          label="Логін"
                          v-model="login"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="login ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12" class="d-flex" v-if="isNew">
            <v-text-field type="password"
                          filled
                          label="Пароль"
                          v-model="password"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="password ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12" class="d-flex" v-if="!isNew">
            <v-checkbox label="Змінити пароль"
                        v-model="change_password" color="success"
                        hide-details class="mr-3"/>
            <v-text-field type="text"
                          filled
                          label="Пароль"
                          v-model="password"
                          hide-details
                          v-if="change_password"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="password ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text"
                          filled
                          label="Базовий URL"
                          v-model="base_url"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="base_url ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <template v-if="isNew">
            <v-col cols="12" md="12">
              <v-text-field type="text"
                            filled
                            label="Базовий токен"
                            v-model="base_token"
                            hide-details
                            color="grey"/>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field type="text"
                            filled
                            label="Базовий ключ"
                            v-model="base_key"
                            hide-details
                            color="grey"/>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field type="text"
                            filled
                            label="ID розсилки"
                            v-model="distribution_id"
                            hide-details
                            color="grey"/>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12">
              <v-checkbox label="Змінити базові дані"
                          v-model="change_base_data"
                          hide-details class="mr-3" color="success"
                          @change="onChangeBaseData"
              />
            </v-col>
            <v-col cols="12" md="12" v-if="change_base_data">
              <v-text-field type="text"
                            filled
                            label="Базовий токен"
                            v-model="base_token"
                            hide-details
                            color="grey"/>
            </v-col>
            <v-col cols="12" md="12" v-if="change_base_data">
              <v-text-field type="text"
                            filled
                            label="Базовий ключ"
                            v-model="base_key"
                            hide-details
                            color="grey"/>
            </v-col>
            <v-col cols="12" md="12" v-if="change_base_data">
              <v-text-field type="text"
                            filled
                            label="ID розсилки"
                            v-model="distribution_id"
                            hide-details
                            color="grey"/>
            </v-col>
          </template>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {
  CREATE_SMS_SEND_CONFIG, UPDATE_SMS_SEND_CONFIG, DELETE_SMS_SEND_CONFIG
} from "@/store/actions/sms_send_config";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {sms_service_sender_type_select, sms_authorization_type_select, sms_send_message_type_select} from '@/utils/icons'
import smsSendConfigAPI from '@/utils/axios/sms_send_config'

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'sms_send_config_modal_delete'

export default {
  name: "HWP_Modal_Checker",
  mixins: [ModalComponentMixin],
  data() {
    return {
      name: this.item.name,
      service_type: this.item.service_type,
      auth_type: this.item.auth_type,
      alpha_name: this.item.alpha_name,
      login: this.item.login,
      password: this.item.password,
      use_refresh_token: this.item.use_refresh_token,
      base_url: this.item.base_url,
      base_token: this.item.base_token,
      base_key: this.item.base_key,
      change_password: false,
      change_base_data: false,
      message_type: this.item.message_type,
      distribution_id: this.item.distribution_id,
      sms_service_sender_type_select,
      sms_authorization_type_select,
      sms_send_message_type_select
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.name = this.item.name
      this.service_type = this.item.service_type
      this.auth_type = this.item.auth_type
      this.alpha_name = this.item.alpha_name
      this.login = this.item.login
      this.password = this.item.password
      this.use_refresh_token = this.item.use_refresh_token
      this.base_url = this.item.base_url
      this.base_token = this.item.base_token
      this.base_key = this.item.base_key
      this.change_password = false
      this.change_base_data = false
      this.distribution_id = this.item.distribution_id
      this.message_type = this.item.message_type

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: 'Підтвердіть вилучення налаштувань смс сервісу',
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Поля, які виділені червоним мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        name: this.name,
        service_type: this.service_type,
        auth_type: this.auth_type,
        alpha_name: this.alpha_name,
        login: this.login,
        use_refresh_token: this.use_refresh_token,
        base_url: this.base_url,
        change_password: this.change_password,
        change_base_data: this.change_base_data,
        password: this.password,
        base_token: this.base_token,
        base_key: this.base_key,
        distribution_id: this.distribution_id,
        message_type: this.message_type
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_SMS_SEND_CONFIG, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_SMS_SEND_CONFIG, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    onChangeBaseData(payload) {
      if (payload) {
        smsSendConfigAPI.get_base_data(this.itemId)
            .then(response => response.data)
            .then(data => {
              this.base_token = data.key1
              this.base_key = data.key2
              this.distribution_id = data.key3
            })
      } else {
        this.base_token = null
        this.base_key = null
        this.distribution_id = null
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.name = payload.name
              this.service_type = payload.service_type
              this.auth_type = payload.auth_type
              this.alpha_name = payload.alpha_name
              this.login = payload.login
              this.use_refresh_token = payload.use_refresh_token
              this.base_url = payload.base_url
              this.change_password = false
              this.change_base_data = false
              this.password = payload.password
              this.base_token = payload.base_token
              this.base_key = payload.base_key
              this.distribution_id = payload.distribution_id
              this.message_type = payload.message_type
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_SMS_SEND_CONFIG, this.itemId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
